.sr-only {
    position:absolute;
    left:-10000px;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden;
}

.strength_wrapper {
    display: block;
    position: relative;
    text-align: left;

    .strength_input {
        background: transparent;
    }

    .button_strength {
        text-decoration: none;
        color: #4d4d4d;
        font-size: 13px;
        display: block;
    }

    .strength_meter {
        position: static;
        left: 0px;
        top: 0px;
        width: 100%;
        z-index: -1;
        overflow: hidden;
        border: 1px solid #000;
        border-radius: 15px;
        height: 22px;
    
        div {
            width: 0%;
            height: 34px;
            -webkit-transition: all .3s ease-in-out;
            -moz-transition: all .3s ease-in-out;
            -o-transition: all .3s ease-in-out;
            -ms-transition: all .3s ease-in-out;
            transition: all .3s ease-in-out;

            &.veryweak {
                background-color: #FF7979;
                width: 25% !important;
                color: #e8e8e8;
                text-shadow: 1px 1px #000;
            }

            &.weak {
                background-color: #FDA068;
                width: 50% !important;
                color: #e8e8e8;
                text-shadow: 1px 1px #000;
            }

            &.medium {
                background-color: #FFE560;
                width: 75% !important;
                color: #1d0e0e;
                text-shadow: 1px 1px #fff;
            }

            &.strong {
                background-color: #9BF47D;
                width: 100% !important;
                color: #1d0e0e;
                text-shadow: 1px 1px #fff;
            }

            p {
                position: absolute;
                left: 10px;
                color: inherit;
                font-size: 13px;
                padding-left: 30px;
                font-weight: bold;
            }
        }
    }
}


/* Styles for verification */
.pswd_info {
    position: absolute;
    width: 300px;
    padding: 15px;
    background: #fefefe;
    font-size: 20px;
    border-radius: 15px;
    box-shadow: 0 1px 3px #ccc;
    border: 1px solid #ddd;
    display: none;
    z-index: 1000;
    top: 62px;
    right: 0;
    background-color: rgba(231, 214, 214, 0.95);
    box-shadow: 5px 5px 5px #888888;

    &:before,
    &:after {
        position: absolute;
        bottom: 100%;
        content: '';
    }

    &:before {
        right: 52px;
        border-right: 17px solid transparent;
        border-bottom: 17px solid #ddd;
        border-left: 17px solid transparent;
    }

    &:after {
        right: 53px;
        border-right: 16px solid transparent;
        border-bottom: 16px solid rgb(238, 238, 238);
        border-left: 16px solid transparent;
    }

    h4 {
        margin: 0 0 10px 0;
        padding: 0;
        font-weight: normal;
        font-size: 1em;
        font-size: 0.7em;
    }
    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
            visibility: visible !important;
            height: 20px !important;
            min-height: 20px !important;
            line-height: 24px;
            font-size: 0.7em;
            padding-left: 24px !important;
        }
    }

    .invalid {
        background: url(images/invalid.png) no-repeat 0 50%;
        color: #860d0f;
    }

    .valid {
        background: url(images/valid.png) no-repeat 0 50%;
        color: #0c3808;
    }
}